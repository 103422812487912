        ul {
          list-style-type: none;
          margin: 0;
          padding: 0;
          text-align: center;
          overflow: scroll;
          background-color: rgb(113, 109, 109);
        }

        .logo {
          width: 10px;
          height: 8px;
        }

        .li {
          padding-left: 3%;
          float: left;
          font-size: larger;
          font-weight: bolder;
          text-align: center;
          text-transform: inherit;
        }

        .li-r {
          float: right;
        }

        li a {
          display: block;
          color: white;
          text-align: center;
          padding: 14px 16px;
          text-decoration: none;
        }

        .active {
          background-color: #6610f2;
          font-size: larger;
        }

        li a:hover {
          background-color: #111;
        }